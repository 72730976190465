import React from "react"
import styled from "styled-components"

const Container = styled.div`
    margin-bottom: 70px;
    padding: 0 20px;
    display: grid;
    grid-template-rows: 350px auto 1fr;
    gap: 15px;
`
const Cover = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* margin-bottom: 50px; */
`
const Heading = styled.h4`
    font-weight: 700;
    margin-bottom: 5px;
`
const Description = styled.p`
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 10px;
`

const EatItem = ({ item }) => {
    return (
        <Container>
            <Cover src={item.image.sourceUrl} />
            <Heading>{item.title}</Heading>
            <Description>{item.description}</Description>
        </Container>
    )
}

export default EatItem
