import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import EatItem from "../components/eat-and-drink/eat-item"
import FooterGallery from "../components/footer-gallery"
import Layout, { MaxWidthContainer } from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block"

const Container = styled.div`
    position: relative;
    display: flex;
`
const EatList = styled(MaxWidthContainer)`
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 3%;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
    }
`

const EatandDrinkPage = ({ data }) => {
    const pageData = data.wpPage.eatsanddrink
    const eats = pageData.eats
    const { footerGallery } = pageData

    return (
        <Layout>
            <SEO title="Eat & Drink" />
            <PageHeader title="Eat & Drink" />
            <TitleBlock title="local flavour, international flair." />
            <Container>
                <EatList>
                    {eats.map((item, index) => (
                        <EatItem key={index} item={item} />
                    ))}
                </EatList>
            </Container>
            {footerGallery && footerGallery.length > 0 &&
                <FooterGallery gallery={footerGallery} />
            }
        </Layout>
    )
}

export default EatandDrinkPage

export const EatQuery = graphql`
    query eatQuery {
        wpPage(title: { eq: "Eat and Drink" }) {
            eatsanddrink {
                footerGallery {
                    sourceUrl
                }
                eats {
                    description
                    title
                    image {
                        sourceUrl
                    }
                }
            }
        }
    }
`
